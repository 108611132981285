import type { Context } from "@squared/context";
// Code generated by @squared/cli, DO NOT EDIT.
import { RPCContextClient } from "@squared/rpc-client";

export type CreateTeamRequest = {
	identifier: string;
	name: string;
	userId: string;
	workspaceId: string;
};

export type CreateTeamResponse = {
	cooldownDuration: number;
	effort: "LINEAR" | "FIBONACCI" | "EXPONENTIAL";
	id: string;
	identifier: string;
	name: string | null;
	sprintDuration: number;
	sprintStartDate: Date;
	sprintsEnabled: boolean;
	tasksPerSprint: number;
	workspaceId: string;
};

export type UpdateTeamRequest = {
	effort: "LINEAR" | "FIBONACCI" | "EXPONENTIAL";
	id: string;
	identifier: string;
	name: string;
};

export type UpdateTeamResponse = {
	cooldownDuration: number;
	effort: "LINEAR" | "FIBONACCI" | "EXPONENTIAL";
	id: string;
	identifier: string;
	name: string | null;
	sprintDuration: number;
	sprintStartDate: Date;
	sprintsEnabled: boolean;
	tasksPerSprint: number;
	workspaceId: string;
};

export type UpdateTeamSprintsRequest = {
	cooldownDuration?: number;
	id: string;
	sprintDuration?: number;
	sprintStartDate?: Date;
	sprintsEnabled?: boolean;
};

export type UpdateTeamSprintsResponse = {
	cooldownDuration: number;
	effort: "LINEAR" | "FIBONACCI" | "EXPONENTIAL";
	id: string;
	identifier: string;
	name: string | null;
	sprintDuration: number;
	sprintStartDate: Date;
	sprintsEnabled: boolean;
	tasksPerSprint: number;
	workspaceId: string;
};

export type DeleteTeamRequest = {
	teamId: string;
};

export type DeleteTeamResponse = {
	cooldownDuration: number;
	effort: "LINEAR" | "FIBONACCI" | "EXPONENTIAL";
	id: string;
	identifier: string;
	name: string | null;
	sprintDuration: number;
	sprintStartDate: Date;
	sprintsEnabled: boolean;
	tasksPerSprint: number;
	workspaceId: string;
};

export type GetTeamRequest = {
	teamId: string;
};

export type GetTeamResponse = {
	cooldownDuration: number;
	effort: "LINEAR" | "FIBONACCI" | "EXPONENTIAL";
	id: string;
	identifier: string;
	name: string | null;
	sprintDuration: number;
	sprintStartDate: Date;
	sprintsEnabled: boolean;
	tasksPerSprint: number;
	workspaceId: string;
} | null;

export type GetTeamByIdentifierRequest = {
	identifier: string;
	workspaceId: string;
};

export type GetTeamByIdentifierResponse = {
	cooldownDuration: number;
	effort: "LINEAR" | "FIBONACCI" | "EXPONENTIAL";
	id: string;
	identifier: string;
	name: string | null;
	sprintDuration: number;
	sprintStartDate: Date;
	sprintsEnabled: boolean;
	tasksPerSprint: number;
	workspaceId: string;
} | null;

export type GetUserTeamsRequest = {
	userId: string;
	workspaceId: string;
};

export type GetUserTeamsResponse = {
	cooldownDuration: number;
	effort: "LINEAR" | "FIBONACCI" | "EXPONENTIAL";
	id: string;
	identifier: string;
	name: string | null;
	sprintDuration: number;
	sprintStartDate: Date;
	sprintsEnabled: boolean;
	tasksPerSprint: number;
	workspaceId: string;
}[];

export type GetWorkspaceTeamsRequest = {
	workspaceId: string;
};

export type GetWorkspaceTeamsResponse = {
	cooldownDuration: number;
	effort: "LINEAR" | "FIBONACCI" | "EXPONENTIAL";
	id: string;
	identifier: string;
	name: string | null;
	sprintDuration: number;
	sprintStartDate: Date;
	sprintsEnabled: boolean;
	tasksPerSprint: number;
	workspaceId: string;
}[];

export type RemoveUserFromTeamRequest = {
	teamId: string;
	userId: string;
};

export type RemoveUserFromTeamResponse = {
	success: boolean;
};

/**
 * team service
 */
export class TeamService extends RPCContextClient {
	constructor(baseUrl: string) {
		super(baseUrl, "team");
	}

	/**
	 * createTeam method
	 */
	createTeam(
		ctx: Context,
		req: CreateTeamRequest,
	): Promise<CreateTeamResponse> {
		return this.request(ctx, "createTeam", req);
	}

	/**
	 * updateTeam method
	 */
	updateTeam(
		ctx: Context,
		req: UpdateTeamRequest,
	): Promise<UpdateTeamResponse> {
		return this.request(ctx, "updateTeam", req);
	}

	/**
	 * updateTeamSprints method
	 */
	updateTeamSprints(
		ctx: Context,
		req: UpdateTeamSprintsRequest,
	): Promise<UpdateTeamSprintsResponse> {
		return this.request(ctx, "updateTeamSprints", req);
	}

	/**
	 * deleteTeam method
	 */
	deleteTeam(
		ctx: Context,
		req: DeleteTeamRequest,
	): Promise<DeleteTeamResponse> {
		return this.request(ctx, "deleteTeam", req);
	}

	/**
	 * getTeam method
	 */
	getTeam(ctx: Context, req: GetTeamRequest): Promise<GetTeamResponse> {
		return this.request(ctx, "getTeam", req);
	}

	/**
	 * getTeamByIdentifier method
	 */
	getTeamByIdentifier(
		ctx: Context,
		req: GetTeamByIdentifierRequest,
	): Promise<GetTeamByIdentifierResponse> {
		return this.request(ctx, "getTeamByIdentifier", req);
	}

	/**
	 * getUserTeams method
	 */
	getUserTeams(
		ctx: Context,
		req: GetUserTeamsRequest,
	): Promise<GetUserTeamsResponse> {
		return this.request(ctx, "getUserTeams", req);
	}

	/**
	 * getWorkspaceTeams method
	 */
	getWorkspaceTeams(
		ctx: Context,
		req: GetWorkspaceTeamsRequest,
	): Promise<GetWorkspaceTeamsResponse> {
		return this.request(ctx, "getWorkspaceTeams", req);
	}

	/**
	 * removeUserFromTeam method
	 */
	removeUserFromTeam(
		ctx: Context,
		req: RemoveUserFromTeamRequest,
	): Promise<RemoveUserFromTeamResponse> {
		return this.request(ctx, "removeUserFromTeam", req);
	}
}
