import type SuperJSON from "./index.js";
import {
	type TypedArrayConstructor,
	isArray,
	isBigint,
	isDate,
	isError,
	isInfinite,
	isMap,
	isNaNValue,
	isRegExp,
	isSet,
	isSymbol,
	isTypedArray,
	isURL,
	isUndefined,
} from "./is.js";
import { findArr } from "./util.js";

export type PrimitiveTypeAnnotation = "number" | "undefined" | "bigint";

type LeafTypeAnnotation =
	| PrimitiveTypeAnnotation
	| "regexp"
	| "Date"
	| "Error"
	| "URL";

type TypedArrayAnnotation = ["typed-array", string];
type ClassTypeAnnotation = ["class", string];
type SymbolTypeAnnotation = ["symbol", string];
type CustomTypeAnnotation = ["custom", string];

type SimpleTypeAnnotation = LeafTypeAnnotation | "map" | "set";

type CompositeTypeAnnotation =
	| TypedArrayAnnotation
	| ClassTypeAnnotation
	| SymbolTypeAnnotation
	| CustomTypeAnnotation;

export type TypeAnnotation = SimpleTypeAnnotation | CompositeTypeAnnotation;

function simpleTransformation<I, O, A extends SimpleTypeAnnotation>(
	isApplicable: (v: any, superJson: SuperJSON) => v is I,
	annotation: A,
	transform: (v: I, superJson: SuperJSON) => O,
	untransform: (v: O, superJson: SuperJSON) => I,
) {
	return {
		isApplicable,
		annotation,
		transform,
		untransform,
	};
}

const simpleRules = [
	simpleTransformation(
		isUndefined,
		"undefined",
		() => null,
		() => undefined,
	),
	simpleTransformation(
		isBigint,
		"bigint",
		(v) => v.toString(),
		(v) => {
			if (typeof BigInt !== "undefined") {
				return BigInt(v);
			}

			console.error("Please add a BigInt polyfill.");

			return v as any;
		},
	),
	simpleTransformation(
		isDate,
		"Date",
		(v) => v.toISOString(),
		(v) => new Date(v),
	),

	simpleTransformation(
		isError,
		"Error",
		(v, superJson) => {
			const baseError: any = {
				name: v.name,
				message: v.message,
			};

			for (const prop in superJson.allowedErrorProps) {
				baseError[prop] = (v as any)[prop];
			}

			return baseError;
		},
		(v, superJson) => {
			const e = new Error(v.message);
			e.name = v.name;
			e.stack = v.stack;

			for (const prop in superJson.allowedErrorProps) {
				(e as any)[prop] = v[prop];
			}

			return e;
		},
	),

	simpleTransformation(
		isRegExp,
		"regexp",
		(v) => `${v}`,
		(regex) => {
			const body = regex.slice(1, regex.lastIndexOf("/"));
			const flags = regex.slice(regex.lastIndexOf("/") + 1);
			return new RegExp(body, flags);
		},
	),

	simpleTransformation(
		isSet,
		"set",
		// (sets only exist in es6+)
		// eslint-disable-next-line es5/no-es6-methods
		(v) => [...v.values()],
		(v) => new Set(v),
	),
	simpleTransformation(
		isMap,
		"map",
		(v) => [...v.entries()],
		(v) => new Map(v),
	),

	simpleTransformation<number, "NaN" | "Infinity" | "-Infinity", "number">(
		(v): v is number => isNaNValue(v) || isInfinite(v),
		"number",
		(v) => {
			if (isNaNValue(v)) {
				return "NaN";
			}

			if (v > 0) {
				return "Infinity";
			}
			return "-Infinity";
		},
		Number,
	),

	simpleTransformation<number, "-0", "number">(
		(v): v is number => v === 0 && 1 / v === Number.NEGATIVE_INFINITY,
		"number",
		() => {
			return "-0";
		},
		Number,
	),

	simpleTransformation(
		isURL,
		"URL",
		(v) => v.toString(),
		(v) => new URL(v),
	),
];

function compositeTransformation<I, O, A extends CompositeTypeAnnotation>(
	isApplicable: (v: any, superJson: SuperJSON) => v is I,
	annotation: (v: I, superJson: SuperJSON) => A,
	transform: (v: I, superJson: SuperJSON) => O,
	untransform: (v: O, a: A, superJson: SuperJSON) => I,
) {
	return {
		isApplicable,
		annotation,
		transform,
		untransform,
	};
}

const symbolRule = compositeTransformation(
	(s, superJson): s is symbol => {
		if (isSymbol(s)) {
			const isRegistered = !!superJson.symbolRegistry.getIdentifier(s);
			return isRegistered;
		}
		return false;
	},
	(s, superJson) => {
		const identifier = superJson.symbolRegistry.getIdentifier(s);
		// biome-ignore lint/style/noNonNullAssertion: This is a symbol, so it should always have a description
		return ["symbol", identifier!];
	},
	(v) => v.description,
	(_, a, superJson) => {
		const value = superJson.symbolRegistry.getValue(a[1]);
		if (!value) {
			throw new Error("Trying to deserialize unknown symbol");
		}
		return value;
	},
);

const constructorToName = [
	Int8Array,
	Uint8Array,
	Int16Array,
	Uint16Array,
	Int32Array,
	Uint32Array,
	Float32Array,
	Float64Array,
	Uint8ClampedArray,
].reduce<Record<string, TypedArrayConstructor>>((obj, ctor) => {
	obj[ctor.name] = ctor;
	return obj;
}, {});

const typedArrayRule = compositeTransformation(
	isTypedArray,
	(v) => ["typed-array", v.constructor.name],
	(v) => [...v],
	(v, a) => {
		const ctor = constructorToName[a[1]];

		if (!ctor) {
			throw new Error("Trying to deserialize unknown typed array");
		}

		return new ctor(v);
	},
);

export function isInstanceOfRegisteredClass(
	potentialClass: any,
	superJson: SuperJSON,
): potentialClass is any {
	if (potentialClass?.constructor) {
		const isRegistered = !!superJson.classRegistry.getIdentifier(
			potentialClass.constructor,
		);
		return isRegistered;
	}
	return false;
}

const classRule = compositeTransformation(
	isInstanceOfRegisteredClass,
	(clazz, superJson) => {
		const identifier = superJson.classRegistry.getIdentifier(clazz.constructor);
		// biome-ignore lint/style/noNonNullAssertion: This is a class, so it should always have an identifier
		return ["class", identifier!];
	},
	(clazz, superJson) => {
		const allowedProps = superJson.classRegistry.getAllowedProps(
			clazz.constructor,
		);
		if (!allowedProps) {
			return { ...clazz };
		}

		const result: any = {};
		for (const prop of allowedProps) {
			result[prop] = clazz[prop];
		}
		return result;
	},
	(v, a, superJson) => {
		const clazz = superJson.classRegistry.getValue(a[1]);

		if (!clazz) {
			throw new Error(
				`Trying to deserialize unknown class '${a[1]}' - check https://github.com/blitz-js/superjson/issues/116#issuecomment-773996564`,
			);
		}

		return Object.assign(Object.create(clazz.prototype), v);
	},
);

const customRule = compositeTransformation(
	(value, superJson): value is any => {
		return !!superJson.customTransformerRegistry.findApplicable(value);
	},
	(value, superJson) => {
		const transformer =
			// biome-ignore lint/style/noNonNullAssertion: We've already checked if it's applicable
			superJson.customTransformerRegistry.findApplicable(value)!;
		return ["custom", transformer.name];
	},
	(value, superJson) => {
		const transformer =
			// biome-ignore lint/style/noNonNullAssertion: We've already checked if it's applicable
			superJson.customTransformerRegistry.findApplicable(value)!;
		return transformer.serialize(value);
	},
	(v, a, superJson) => {
		const transformer = superJson.customTransformerRegistry.findByName(a[1]);
		if (!transformer) {
			throw new Error("Trying to deserialize unknown custom value");
		}
		return transformer.deserialize(v);
	},
);

const compositeRules = [classRule, symbolRule, customRule, typedArrayRule];

export const transformValue = (
	value: any,
	superJson: SuperJSON,
): { value: any; type: TypeAnnotation } | undefined => {
	const applicableCompositeRule = findArr(compositeRules, (rule) =>
		rule.isApplicable(value, superJson),
	);
	if (applicableCompositeRule) {
		return {
			value: applicableCompositeRule.transform(value as never, superJson),
			type: applicableCompositeRule.annotation(value, superJson),
		};
	}

	const applicableSimpleRule = findArr(simpleRules, (rule) =>
		rule.isApplicable(value, superJson),
	);

	if (applicableSimpleRule) {
		return {
			value: applicableSimpleRule.transform(value as never, superJson),
			type: applicableSimpleRule.annotation,
		};
	}

	return undefined;
};

const simpleRulesByAnnotation: Record<string, (typeof simpleRules)[0]> = {};
for (const rule of simpleRules) {
	simpleRulesByAnnotation[rule.annotation] = rule;
}

export const untransformValue = (
	json: any,
	type: TypeAnnotation,
	superJson: SuperJSON,
) => {
	if (isArray(type)) {
		switch (type[0]) {
			case "symbol":
				return symbolRule.untransform(json, type, superJson);
			case "class":
				return classRule.untransform(json, type, superJson);
			case "custom":
				return customRule.untransform(json, type, superJson);
			case "typed-array":
				return typedArrayRule.untransform(json, type, superJson);
			default:
				throw new Error(`Unknown transformation: ${type}`);
		}
	}
	const transformation = simpleRulesByAnnotation[type];
	if (!transformation) {
		throw new Error(`Unknown transformation: ${type}`);
	}

	return transformation.untransform(json as never, superJson);
};
